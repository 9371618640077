import {
  Card,
  CardWrapper,
  DataGrid,
  FormFirstFocus,
  Icon,
  Label,
  LabeledTextInput,
  ModalButton,
  PlatformFoutenSamenvatting,
  useFeature
} from "adviesbox-shared";
import { Form, FormikProps, useFormikContext, FormikContextType } from "formik";
import React, { ReactElement, useContext, useState } from "react";
import { Column, CellInfo } from "react-table-6";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import { SaveButton } from "../save-button/save-button";
import { Debug } from "../shared/components/formik/Debug";
import {
  KolommenInstellenModalState,
  RapportageSamenstellenProps,
  RapportageSamenstellenState,
  RapportageStructuurType
} from "../shared/components/rapportage/infra/rapportage-samenstellen-types";
import { defaultElementen } from "../shared/components/rapportage/infra/rapportage-structuur-types";
import {
  rapportageSamenstellenSchema,
  rapportageStructuurSchema
} from "../shared/components/rapportage/rapportage-samenstellen-schema";
import { RapportageStructuur } from "../shared/components/rapportage/rapportage-structuur";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import KolommenInstellenModal from "./kolommen-instellen-modal";

export const getNewRapportageStructuurFactory = (
  nextVolgnummer: number,
  defaultStructuur: RapportageStructuurType
): RapportageStructuurType => {
  const newRapportageStructuur = { ...defaultStructuur };
  newRapportageStructuur.naam = "Volledig Financieel Plan";
  newRapportageStructuur.elementen = [...defaultElementen];
  newRapportageStructuur.volgnummer = nextVolgnummer;

  return newRapportageStructuur;
};

// Toon alleen een delete button als isVerwijderbaar true is
const DeleteButton = ({
  row,
  formik
}: {
  row: CellInfo;
  formik: FormikContextType<RapportageSamenstellenState>;
}): ReactElement => {
  const { setFieldValue, values } = formik;
  const onClick = (): void => {
    const curr = values.structuren;
    curr?.splice(row.index, 1);
    setFieldValue("structuren", curr);
    setFieldValue("dirty", true);
  };

  return (
    <>
      {row.original.isVerwijderbaar && (
        <div data-testid={`delete-rapportage-${row.index}`} id={`delete-rapportage-${row.index}`} onClick={onClick}>
          <Icon name="prullenbak" alt="prullenbak" />
        </div>
      )}
    </>
  );
};

const RapportageSamenstellenComponent = (
  props: FormikProps<RapportageSamenstellenState> &
    RapportageSamenstellenProps &
    WithSaveData<RapportageSamenstellenState>
): ReactElement => {
  const { values, setFieldValue } = useFormikContext<RapportageSamenstellenState>();
  const selectedState = useState(0);
  const [selected] = selectedState;
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  const featureRapport2 = useFeature("FeatureRapport2");

  // TODO: re-enable when platform issues are all fixed

  // const [filesLength, setFilesLength] = useState<number>(0);
  // const filesRef = useRef<FileWithName[]>([]);

  // const { params, user } = useRequestInit();

  // const extraHeaders = useMemo((): UseRequestInitOptions => {
  //   return {
  //     method: "GET",
  //     extraHeaders: {
  //       "content-type": "application/json"
  //     }
  //   };
  // }, []);

  //const { settings } = useRequestInit(extraHeaders);

  // const rapportageImportUrl = `${settings.instellingenFormsOrigin}/Rapportage/Import`;

  // if (!user) {
  //   return <ErrorPage error={new Error("Missing AuthContext")} />;
  // }

  const rapportageSamenstellenMasterDetailDataGrid: Column[] = [
    {
      Header: "Naam rapportstructuur",
      accessor: "naam"
    },
    // {
    //   id: "downloadRapportage",
    //   /* eslint-disable react/display-name */
    //   Cell: (c): ReactElement => (
    //     <Button
    //       size={"sm"}
    //       variant={"seconday"}
    //       onMouseUp={async () => {
    //         await downloadRapportageFile(settings, requestInit, c.index);
    //       }}
    //       data-testid={`download-rapportage-${c.index}`}
    //     >
    //       <Icon name={"download"} key={c.index} />
    //     </Button>
    //   ),
    //   width: 40
    // },
    {
      Cell: c => DeleteButton({ formik: props, row: c }),
      maxWidth: 30
    }
  ];

  return (
    <FormFirstFocus>
      <Form className={"px-3"}>
        <PlatformFoutenSamenvatting />
        <fieldset disabled={!isBeheerder}>
          <CardWrapper>
            <div className="text-container">
              <h2>Rapportage samenstellen</h2>
              <div className="save-btn-position">
                <SaveButton context={props} />
              </div>
              {/* <SaveButton
                context={{
                  ...props,
                  saveData: saveDataWithFiles(
                    user,
                    params.vestiging ?? "",
                    rapportageImportUrl,
                    settings.OcpApimSubscriptionKey,
                    filesRef.current,
                    mapRapportageImporterenDlTargerToUiField
                  )
                }}
                btnTekst={`${filesLength === 1 ? "bestand" : filesLength + " bestanden"} opslaan`}
              /> */}
            </div>
          </CardWrapper>
          <CardWrapper className="master-detail-card flex-grow-1" maxRowCount={4}>
            <Card className="w-xl-100 w-lg-100 w-md-50 w-50 master-detail-card">
              <DataGrid
                masterDetail
                rowSelected={selectedState}
                columns={rapportageSamenstellenMasterDetailDataGrid}
                name="structuren"
                showButtonDelete
                showButtonAddRow
                validationSchema={rapportageStructuurSchema}
                // additionalButton={
                //   <ModalButton
                //     parent="rapportagestructuur-importeren"
                //     size="lg"
                //     content={"Rapportstructuur importeren"}
                //     className={"ml-3 btn-primary"}
                //   >
                //     <RapportageImporterenModal
                //       saveModal={(files: File[]) => {
                //         setFilesLength(files.length);
                //         setFieldValue("newFileAdded", true);
                //         filesRef.current = files.map(file => ({ ...file, name: "rapportStructuurImportBestand" }));
                //         return files;
                //       }}
                //     />
                //   </ModalButton>
                // }
                getNewRowValues={() => {
                  const volgnummers = values.structuren.map(c => c.volgnummer);
                  const nextVolgnummer =
                    volgnummers.length > 0 ? /* istanbul ignore next */ ++volgnummers.sort().reverse()[0] : 1;
                  return featureRapport2
                    ? getNewRapportageStructuurFactory(nextVolgnummer, values.defaultStructuur)
                    : getNewRapportageStructuurFactory(nextVolgnummer, rapportageStructuurSchema.default());
                }}
              />
            </Card>
            {values.structuren[selected] && (
              <Card title="Algemene instellingen" className="w-xl-100 w-lg-100 w-md-50 w-50">
                <div className={"w-50"}>
                  <LabeledTextInput name={`structuren[${selected}].naam`} caption={"Naam rapportstructuur"} />

                  <div className={"row px-1"}>
                    <div className={"col-6"}>
                      <Label caption={"Kolommen instellen"} />
                    </div>
                    <div className={"col-6 pl-1"}>
                      <ModalButton parent={`structuren[${selected}].kolomselectie`} content={<Icon name={"copy"} />}>
                        <KolommenInstellenModal
                          saveModal={
                            /* istanbul ignore next */ (data: KolommenInstellenModalState) => {
                              setFieldValue(`structuren[${selected}].kolomselectie`, data);
                            }
                          }
                          values={values.structuren[selected].kolomselectie as any}
                          rapportNaam={values.structuren[selected].naam}
                        />
                      </ModalButton>
                    </div>
                  </div>
                </div>
                <br />
                <h2>Structuur</h2>
                <RapportageStructuur
                  elementen={values.structuren[selected].elementen}
                  parent={`structuren[${selected}]`}
                  parentKey={0}
                />
              </Card>
            )}
          </CardWrapper>
        </fieldset>
        <Debug />
      </Form>
    </FormFirstFocus>
  );
};

export const RapportageSamenstellen = withAdviesboxFormik<
  RapportageSamenstellenProps & WithSaveData<RapportageSamenstellenState>,
  RapportageSamenstellenState
>({
  // Transform outer props into form values
  mapPropsToValues: (e: RapportageSamenstellenProps): RapportageSamenstellenState => e,
  validationSchema: rapportageSamenstellenSchema
})(RapportageSamenstellenComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  RapportageSamenstellen.displayName = "RapportageSamenstellen";
