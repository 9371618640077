import * as Yup from "yup";
import { StandaardRapportOptions } from "../../../.generated/instellingen-forms/instellingen-formstypes";
import { yupEnum } from "../../utils/yup-enum";
import { RapportageElementenType, RapportageElementSoort } from "./infra/rapportage-structuur-types";

export const kolommenLastenSchema = Yup.object({
  brutoInkomen: Yup.boolean().default(false),
  somHypotheken: Yup.boolean().default(true),
  waardeopbouw: Yup.boolean().default(false),
  huur: Yup.boolean().default(false),
  rente: Yup.boolean().default(true),
  hypotheekAflossing: Yup.boolean().default(true),
  uitkeringKapitaalverzekering: Yup.boolean().default(true),
  verzekeringspremies: Yup.boolean().default(true),
  spaarBeleggingsdepots: Yup.boolean().default(true),
  opnameUitDepot: Yup.boolean().default(true),
  kredietLast: Yup.boolean().default(true),
  overigeUitgaven: Yup.boolean().default(true),
  brutoLast: Yup.boolean().default(true),
  fiscaalVoordeel: Yup.boolean().default(true),
  vrh: Yup.boolean().default(true),
  nettoLast: Yup.boolean().default(true)
});

export const kolommenNbiSchema = Yup.object({
  hypotheek: Yup.boolean().default(true),
  verzekeringen: Yup.boolean().default(true),
  spaarBeleggingsdepots: Yup.boolean().default(true),
  kredietLast: Yup.boolean().default(true),
  overigeUitgaven: Yup.boolean().default(true),
  brutoLast: Yup.boolean().default(true),
  inkomstenbelasting: Yup.boolean().default(true),
  vrh: Yup.boolean().default(true),
  nettoInkomen: Yup.boolean().default(true),
  nbi: Yup.boolean().default(true)
});

export const kolommenInstellenModalSchema = Yup.object({
  kolommenLasten: kolommenLastenSchema,
  kolommenNbi: kolommenNbiSchema
});

export const rapportageElementSchema: Yup.ObjectSchema<RapportageElementenType> = Yup.object().shape({
  code: Yup.number(),
  naam: Yup.string(),
  geselecteerd: Yup.boolean(),
  soortElement: yupEnum(RapportageElementSoort),
  volgnummer: Yup.number(),
  elementen: Yup.array<RapportageElementenType>().default([]),
  inhoud: Yup.string()
    .nullable()
    .default(null),
  defaultInhoud: Yup.string()
    .nullable()
    .default(null),
  perAdviesAanpasbaar: Yup.boolean()
    .nullable()
    .default(null)
});

export const rapportageStructuurSchema = Yup.object({
  structuurId: Yup.string(),
  naam: Yup.string(),
  volgnummer: Yup.number(),
  standaardRapport: yupEnum(StandaardRapportOptions).default(StandaardRapportOptions.VolledigFinancieelPlan),
  kolomselectie: kolommenInstellenModalSchema,
  elementen: Yup.array<RapportageElementenType>(rapportageElementSchema).default([]),
  isVerwijderbaar: Yup.boolean().default(true)
});

export const rapportageSamenstellenSchema = Yup.object({
  structuren: Yup.array(rapportageStructuurSchema).default([]),
  defaultStructuur: rapportageStructuurSchema,
  movetoparent: Yup.string()
    .nullable()
    .default(null)
});
